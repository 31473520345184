import styled from "styled-components";
import { fonts, landingPalette } from "../styles";

export const HeroSection = styled.section`
  padding: 3.8rem 80px 9rem 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    padding: 3.8rem 32px 9rem 32px;
  }

  @media (max-width: 425px) {
    padding: 3.8rem 16px 9rem 16px;
  }

  h1 {
    font-size: 2.3rem;
    line-height: 2.9rem;
    color: ${landingPalette.orange};
    font-family: ${fonts.SofiaProMedium};
    margin-bottom: 2rem;
  }

  h3 {
    font-size: 3.8rem;
    line-height: 4.8rem;
    color: ${landingPalette.blue};
    font-family: ${fonts.SofiaProBold};
    text-align: center;
    max-width: 62rem;
    margin-bottom: 3.2rem;
  }

  p {
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: ${landingPalette.blue};
    font-family: ${fonts.SofiaProMedium};
    text-align: center;
    max-width: 56rem;
    margin-bottom: 5rem;
  }

  button,
  a {
    border: none;
    outline: none;
    background-color: ${landingPalette.orange};
    border-radius: 21px;
    min-height: 44px;
    padding: 10px 30px;
    font-family: ${fonts.SofiaProMedium};
    font-size: 1.6rem;
    color: white;
    line-height: 2rem;
    margin-bottom: 3.5rem;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
  }

  figure {
    max-width: 1038px;
    position: relative;

    .main-image-hero {
      width: 100%;
      object-fit: scale-down;
    }

    .hero-figure {
      position: absolute;
    }

    .hero-figure-one {
      left: 9%;
      top: -6rem;

      @media (max-width: 920px) {
        left: 4%;
        top: -6rem;
        width: 24px;
      }

      @media (max-width: 480px) {
        left: -16px;
      }

      @media (max-width: 375px) {
        top: 0;
      }
    }

    .hero-figure-two {
      right: 9%;
      top: -6rem;

      @media (max-width: 920px) {
        right: 4%;
        top: -6rem;
        width: 24px;
      }

      @media (max-width: 480px) {
        right: -16px;
      }

      @media (max-width: 375px) {
        top: 0;
      }
    }

    .hero-figure-three {
      left: 0;
      top: 2rem;

      @media (max-width: 920px) {
        width: 16px;
      }

      @media (max-width: 480px) {
        left: -16px;
      }

      @media (max-width: 375px) {
        top: 10rem;
      }
    }

    .hero-figure-four {
      right: 0;
      top: 2rem;

      @media (max-width: 920px) {
        width: 16px;
      }

      @media (max-width: 480px) {
        right: -16px;
      }

      @media (max-width: 375px) {
        top: 10rem;
      }
    }
  }
`;

export const SoftwareSection = styled.section`
  padding: 0 80px 9rem 80px;

  @media (max-width: 600px) {
    padding: 0 32px 9rem 32px;
  }

  @media (max-width: 425px) {
    padding: 0 16px 9rem 16px;
  }

  h2 {
    font-size: 2.3rem;
    line-height: 2.9rem;
    color: ${landingPalette.orange};
    font-family: ${fonts.SofiaProMedium};
    margin-bottom: 8rem;
    text-align: center;
  }

  .main-landing-container-row {
    display: flex;
    justify-content: space-between;
    grid-gap: 5rem;

    @media (max-width: 1024px) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  figure {
    max-width: 53rem;
    width: 100%;

    img {
      width: 100%;
      object-fit: scale-down;
    }
  }

  article {
    max-width: 45rem;
    width: 100%;

    h3 {
      font-size: 3.8rem;
      line-height: 4.8rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProSemiBold};
      margin-bottom: 2rem;
    }

    .description-article {
      font-size: 1.8rem;
      line-height: 2.8rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProMedium};
      margin-bottom: 2rem;
    }

    .message-article {
      font-size: 2.4rem;
      line-height: 4.8rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProMedium};
      margin-bottom: 2rem;
    }

    button,
    a {
      border: none;
      outline: none;
      background-color: ${landingPalette.orange};
      border-radius: 21px;
      height: 44px;
      padding: 0 30px;
      font-family: ${fonts.SofiaProMedium};
      font-size: 1.6rem;
      color: white;
      line-height: 2rem;
      margin-bottom: 3.5rem;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
    }
  }
`;

export const CareerPageSection = styled.section`
  padding: 6.5rem 80px 11.1rem 80px;
  background-color: ${landingPalette.bgBlue};
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    padding: 6.5rem 80px 0 80px;
  }

  @media (max-width: 600px) {
    padding: 6.5rem 32px 0 32px;
  }

  @media (max-width: 425px) {
    padding: 6.5rem 16px 0 16px;
  }

  .main-landing-container-row {
    position: relative;

    @media (max-width: 1024px) {
      flex-direction: column;
      grid-gap: 3.2rem;
    }
  }

  article {
    max-width: 44rem;
    width: 100%;

    h2 {
      font-size: 3.8rem;
      line-height: 4.8rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProSemiBold};
      margin-bottom: 2rem;
    }

    p {
      font-size: 1.8rem;
      line-height: 2.8rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProMedium};
    }
  }

  figure {
    max-width: 680px;
    width: 100%;
    position: absolute;
    bottom: -11.1rem;
    right: -50px;

    img {
      width: 100%;
      object-fit: scale-down;
    }

    @media (max-width: 1240px) {
      position: relative;
    }

    @media (max-width: 1024px) {
      max-width: 600px;
      bottom: 0;
      right: auto;
    }
  }
`;

export const SuggestionSection = styled.section`
  padding: 10rem 80px 10rem 80px;

  @media (max-width: 600px) {
    padding: 10rem 32px 10rem 32px;
  }

  @media (max-width: 425px) {
    padding: 10rem 16px 10rem 16px;
  }

  .suggestion-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10rem;
    grid-gap: 3.2rem;

    @media (max-width: 920px) {
      grid-gap: 5rem;
      flex-direction: column-reverse;
    }

    figure {
      max-width: 44rem;
      width: 100%;

      img {
        width: 100%;
        object-fit: scale-down;
      }
    }

    article {
      max-width: 41.3rem;
      width: 100%;

      h2 {
        font-size: 1.6rem;
        line-height: 2.9rem;
        color: ${landingPalette.orange};
        font-family: ${fonts.SofiaProMedium};
        margin-bottom: 2rem;
      }

      h3 {
        font-size: 3.8rem;
        line-height: 4.8rem;
        color: ${landingPalette.blue};
        font-family: ${fonts.SofiaProSemiBold};
        margin-bottom: 2rem;
      }

      p {
        font-size: 1.8rem;
        line-height: 2.8rem;
        color: ${landingPalette.blue};
        font-family: ${fonts.SofiaProMedium};
      }
    }
  }

  .candidates-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8rem;

    @media (max-width: 920px) {
      grid-gap: 5rem;
      flex-direction: column;
    }

    figure {
      max-width: 46rem;
      width: 100%;

      img {
        width: 100%;
        object-fit: scale-down;
      }
    }

    article {
      max-width: 44rem;
      width: 100%;

      h2 {
        font-size: 1.6rem;
        line-height: 2.9rem;
        color: ${landingPalette.orange};
        font-family: ${fonts.SofiaProMedium};
        margin-bottom: 2rem;
      }

      h3 {
        font-size: 3.8rem;
        line-height: 4.8rem;
        color: ${landingPalette.blue};
        font-family: ${fonts.SofiaProSemiBold};
        margin-bottom: 2rem;
      }

      p {
        font-size: 1.8rem;
        line-height: 2.8rem;
        color: ${landingPalette.blue};
        font-family: ${fonts.SofiaProMedium};
      }
    }
  }

  .info-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-gap: 5rem;

    @media (max-width: 1024px) {
      flex-wrap: wrap;
      justify-content: space-around;
    }

    article {
      max-width: 36rem;
      width: 100%;

      img {
        width: 45px;
        height: 45px;
        object-fit: cover;
        border-radius: 4px;
        margin-bottom: 2rem;
      }

      h5 {
        font-size: 1.8rem;
        line-height: 3.2rem;
        color: #003245;
        font-family: ${fonts.SofiaProSemiBold};
        margin-bottom: 1rem;
      }

      p {
        font-size: 1.6rem;
        line-height: 2.8rem;
        color: #232323;
        font-family: ${fonts.sofiaProRegular};
      }
    }
  }
`;

export const InfoSection = styled.section`
  padding: 7rem 80px 7rem 80px;
  background-color: ${landingPalette.bgBlue};

  @media (max-width: 600px) {
    padding: 7rem 16px 7rem 16px;
  }

  @media (max-width: 425px) {
    padding: 7rem 16px 7rem 16px;
  }

  h2 {
    font-size: 3.2rem;
    line-height: 4.8rem;
    color: #003245;
    font-family: ${fonts.SofiaProBold};
    text-align: center;
    margin-bottom: 5rem;
  }

  .info-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 332px));
    justify-content: space-around;
    grid-gap: 7rem;

    article {
      max-width: 320px;
      width: 100%;
      display: flex;
      flex-direction: column;

      h5 {
        font-size: 1.8rem;
        line-height: 2.2rem;
        color: ${landingPalette.blueText};
        font-family: ${fonts.SofiaProSemiBold};
        margin-bottom: 1rem;
      }

      p {
        font-size: 1.5rem;
        line-height: 2.2rem;
        color: ${landingPalette.blue};
        font-family: ${fonts.sofiaProRegular};
      }

      .info-icon {
        align-self: center;
        margin-bottom: 3.2rem;
      }
    }
  }
`;

export const PlatformSection = styled.section`
  padding: 12rem 80px 10rem 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;

  @media (max-width: 600px) {
    padding: 12rem 32px 10rem 32px;
  }

  @media (max-width: 425px) {
    padding: 12rem 16px 10rem 16px;
  }

  .main-landing-container-row {
    position: relative;

    @media (max-width: 768px) {
      flex-direction: column;
      grid-gap: 5rem;
    }
  }

  article {
    max-width: 41.3rem;
    width: 100%;

    h4 {
      font-size: 1.6rem;
      line-height: 2.9rem;
      color: ${landingPalette.orange};
      font-family: ${fonts.SofiaProMedium};
      margin-bottom: 1.6rem;
    }

    h5 {
      font-size: 3.8rem;
      line-height: 4.8rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProSemiBold};
      margin-bottom: 3.2rem;
    }

    p {
      font-size: 1.8rem;
      line-height: 2.8rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProMedium};
      margin-bottom: 3.2rem;
    }

    button,
    a {
      border: none;
      outline: none;
      background-color: ${landingPalette.orange};
      border-radius: 21px;
      height: 44px;
      padding: 0 30px;
      font-family: ${fonts.SofiaProMedium};
      font-size: 1.6rem;
      color: white;
      line-height: 2rem;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
    }
  }

  figure {
    max-width: 60rem;
    width: 100%;
    position: absolute;
    right: -250px;

    @media (max-width: 920px) {
      position: relative;
      right: -10%;
    }

    @media (max-width: 768px) {
      position: relative;
      right: auto;
      max-width: 50rem;
    }

    img {
      width: 100%;
      object-fit: scale-down;
    }
  }
`;

export const ContactSection = styled.section`
  padding: 0 80px 9rem 80px;

  @media (max-width: 600px) {
    padding: 0 32px 9rem 32px;
  }

  @media (max-width: 425px) {
    padding: 0 16px 9rem 16px;
  }

  h2 {
    font-size: 3.8rem;
    line-height: 4.8rem;
    color: white;
    font-family: ${fonts.SofiaProBold};
    margin-bottom: 4rem;
    text-align: center;
  }

  > div {
    padding: 7rem 3.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${landingPalette.blueFour};
    border-radius: 10px;
    position: relative;
  }

  button,
  a {
    border: none;
    outline: none;
    background-color: ${landingPalette.orange};
    border-radius: 21px;
    min-height: 44px;
    max-width: 386px;
    width: 100%;
    padding: 10px 32px;
    margin-bottom: 5rem;
    font-family: ${fonts.SofiaProMedium};
    font-size: 1.6rem;
    color: white;
    line-height: 2rem;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  p {
    font-size: 2.2rem;
    line-height: 2.5rem;
    color: white;
    font-family: ${fonts.SofiaProMedium};
    margin-bottom: 3.2rem;
    text-align: center;
  }

  .companies-list {
    width: 100%;
  }

  .hero-figure {
    position: absolute;
  }

  .hero-figure-one {
    left: 10%;
    top: 6rem;
    width: 32px;

    @media (max-width: 920px) {
      left: 8px;
      top: 16px;
      width: 24px;
    }
  }

  .hero-figure-two {
    right: 10%;
    top: 6rem;
    width: 32px;

    @media (max-width: 920px) {
      right: 8px;
      top: 16px;
      width: 24px;
    }
  }

  .hero-figure-three {
    left: 5%;
    top: 35%;
    width: 22px;

    @media (max-width: 920px) {
      top: 30%;
      width: 16px;
    }

    @media (max-width: 375px) {
      bottom: 0;
    }
  }

  .hero-figure-four {
    right: 5%;
    top: 35%;
    width: 22px;

    @media (max-width: 920px) {
      top: 30%;
      width: 16px;
    }

    @media (max-width: 375px) {
      bottom: 0;
    }
  }
`;
